import { React, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap"
import Api from "../modules/Api"

const SetPasswordForm = () => {
    const [queryParams] = useSearchParams()
    const navigate = useNavigate()
    const [response, setResponse] = useState({ data: { errors: [] }, status: "" })
    const { register, handleSubmit, formState, watch, trigger } = useForm({ mode: "onChange" })
    const passwords = watch()

    const onSubmit = async (data) => {
        const {data:result, status} = await Api.User.resetPassword({new_password: data.password, password_token: queryParams.get("token")})
        if (status === 204) {
            navigate("/login")
        }
        else {
            setResponse({status: status, data: result})
        }
    }

    const closeModal = () => {
        setResponse({ data: { errors: [] }, status: "" })
    }

    return (
        <Container className="minHeight">
            <Row className="justify-content-center">
                <Col md={6} className="mt-3 pb-3 bg-light rounded shadow">
                    <h1 className="text-center">Восстановление пароля</h1>
                    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="password">Новый пароль</Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                name="password"
                                {...register("password", { required: true, onChange: () => {
                                    trigger()
                                } })}
                                isValid={formState.touchedFields.password && !formState.errors.password}
                                isInvalid={!!formState.errors.password}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formState.errors.password?.type === "required" && "Пожалуйста, введите новый пароль"}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="confirm_password">Подтвердите пароль</Form.Label>
                            <Form.Control
                                type="password"
                                id="confirm_password"
                                name="confirm_password"
                                {...register("confirm_password", { required: true, validate: value => value == passwords.password || "Пароли не совпадают", onChange: () => {
                                    trigger()
                                } })}
                                isValid={formState.touchedFields.confirm_password && !formState.errors.confirm_password && passwords.password === passwords.confirm_password}
                                isInvalid={!!formState.errors.confirm_password}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formState.errors.confirm_password && "Пароли не совпадают"}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className="d-grid">
                            <button type="submit" className="btn btn-dark mt-2">Обновить пароль</button>
                        </div>
                    </Form>
                </Col>
            </Row>
            {
                response.status !== 204 &&
                <Modal show={response.status} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Упс...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{`Что-то пошло не так: ${response.data.errors.join('\n')}`}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>Закрыть</Button>
                    </Modal.Footer>
                </Modal>
            }
        </Container>
    )
}

export default SetPasswordForm