import axios from 'axios'
import { io } from 'socket.io-client'
import { getCookie } from './utils'

class Api {
    static host = 'https://geekly.nazarov-do-it.ru'
    static #axios = axios.create({
        baseURL: `${Api.host}/api`,
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        },
        validateStatus: (status) => {
            return status >= 200 && status < 500
        }
    })
    static socket = io(Api.host)

    static async upload(body) {
        return Api.#axios.post('/upload', body)
    }

    static #User = class User {
        static async register(body) {
            return Api.#axios.post('/users', body)
        }

        static async getAll() {
            return Api.#axios.get('/users')
        }

        static async get(id) {
            return Api.#axios.get(`/users/${id}`)
        }

        static async getMe() {
            return Api.#axios.get(`/users/me`)
        }

        static async update(id, body) {
            return Api.#axios.patch(`/users/${id}`, body)
        }

        static async delete(id) {
            return Api.#axios.delete(`/users/${id}`)
        }

        static async login(body) {
            return Api.#axios.post('/login', body)
        }

        static async logout(userId) {
            const response = await Api.#axios.post('/logout')
            Api.socket.emit('logout', { user_id: userId })
            return response
        }

        static async getFriends(id) {
            return Api.#axios.get(`/users/${id}/friends`)
        }

        static async createFriendRequest(body) {
            return Api.#axios.post(`/friendship`, body)
        }


        static async getFriendRequests() {
            return Api.#axios.get(`/friendship`)
        }

        static async updateFriendshipStatus(requestId) {
            return Api.#axios.patch(`/friendship/${requestId}`)
        }

        static async deleteFriendRequest(body) {
            return Api.#axios.delete(`/friendship`, body)
        }

        static async getChats(id) {
            return Api.#axios.get(`/users/${id}/chats`)
        }

        static async getMessages(id) {
            return Api.#axios.get(`/users/${id}/messages`)
        }

        static async sendMessage(body) {
            return Api.#axios.post('/messages', body)
        }

        static async updateMessage(messageId, body) {
            return Api.#axios.patch(`/messages/${messageId}`, body)
        }

        static async deleteMessages(body) {
            return Api.#axios.delete('/messages', body)
        }

        static async readMessage(messageId) {
            return Api.#axios.patch(`/messages/${messageId}/read`)
        }

        static async getUnreadMessagesCount(id) {
            return Api.#axios.get(`/users/${id}/messages/unread`)
        }

        static async sendMailForgotPassword(body) {
            return Api.#axios.post('/forgot_password', body)
        }

        static async resetPassword(body) {
            return Api.#axios.post('/reset_password', body)
        }
    }

    static #Post = class Post {
        static async create(body) {
            return Api.#axios.post('/posts', body)
        }

        static async getAll() {
            return Api.#axios.get('/posts')
        }

        static async get(id) {
            return Api.#axios.get(`/posts/${id}`)
        }

        static async getByAuthor(id) {
            return Api.#axios.get(`/users/${id}/posts`)
        }

        static async update(id, body) {
            return Api.#axios.patch(`/posts/${id}`, body)
        }

        static async delete(id, body) {
            return Api.#axios.delete(`/posts/${id}`, body)
        }

        static async getComments(id) {
            return Api.#axios.get(`/posts/${id}/comments`)
        }

        static async createComment(id, body) {
            return Api.#axios.post(`/posts/${id}/comments`, body)
        }

        static async isPostLiked(id) {
            return Api.#axios.get(`/posts/${id}/like`)
        }

        static async likePost(id) {
            return Api.#axios.post(`/posts/${id}/like`)
        }
    }

    static get Post() {
        return Api.#Post
    }

    static get User() {
        return Api.#User
    }
}

export default Api