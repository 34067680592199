import React, { useState, useEffect, useRef } from 'react'
import Api from '../modules/Api'
import { useProfile } from '../modules/profileContext'
import { useForm } from "react-hook-form"
import { Form, Button } from 'react-bootstrap'
import imagePreview from '../modules/imagePreview'

const CreatePostForm = ({ post, onCancel, onPostCreated, onPostUpdated }) => {
    const { profile } = useProfile()
    const ref = useRef(null)
    const { register, handleSubmit, formState, setError, clearErrors, reset, trigger, getValues } = useForm({ mode: "onChange" })
    const [selectedImage, setSelectedImage] = useState(null)
    const [imageDeleted, setImageDeleted] = useState(false)
    const [previewImage, setPreviewImage] = useState(null)
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => { validatePostContent() }, [selectedImage, submitted])
    const validatePostContent = () => {
        if (!submitted) return false
        const textValue = getValues('text')?.trim() || ""
        if (!textValue && !selectedImage) {
            setError('submit', { type: 'manual', message: 'Необходимо добавить текст или изображение' })
            return false
        }
        else {
            clearErrors('submit')
            return true
        }
    }

    useEffect(() => {
        if (profile) {
            trigger('text')
        }
    }, [profile, trigger])

    const resetAll = () => {
        reset()
        setSelectedImage(null)
        setPreviewImage(null)
        ref.current.value = null
        setSubmitted(null)
        setImageDeleted(false)
    }

    const uploadPostImage = async (file) => {
        const formData = new FormData()
        formData.append('file', file)
        const { data, status } = await Api.upload(formData)
        if (status === 201) {
            return data.data
        }
    }

    const newPost = async (data) => {
        setSubmitted(true)
        if (!validatePostContent()) return false
        if (typeof (data.text) == "string") {
            data.text = data.text.trim()
            if (!data.text) data.text = null
        }

        if (selectedImage) {
            const imagePath = await uploadPostImage(selectedImage)
            if (imagePath) {
                data = {
                    ...data,
                    image: imagePath
                }
            }
        }

        if (post) {
            updatePost(data)
        }
        else {
            createPost(data)
        }
    }

    const updatePost = async (data) => {
        data = { ...data, text: data.text }
        if (imageDeleted) {
            data.image = null
        }
        else if (selectedImage) {
            const imagePath = await uploadPostImage(selectedImage)
            if (imagePath) {
                data.image = imagePath
            }
            else {
                data.image = null
            }
        }
        const { status } = await Api.Post.update(post.id, data)
        if (status === 200) {
            if (onPostUpdated) onPostUpdated()
        }
    }


    useEffect(() => {
        if (post) {
            reset({
                text: post.text,
            })
            setPreviewImage(post.image ? `${Api.host}${post.image}` : null)
        }
    }, [post])

    const createPost = async (data) => {
        const { status } = await Api.Post.create(profile.id, data)
        if (status === 201) {
            if (onPostCreated) onPostCreated()
            resetAll()
        }
    }

    const handleDeleteImage = () => {
        setSelectedImage(null)
        setPreviewImage(null)
        ref.current.value = null
        setImageDeleted(true)
    }

    return (
        <Form onSubmit={handleSubmit(newPost)} className='bg-light p-3' noValidate>
            <Form.Group>
                <Form.Label>Текст поста:</Form.Label>
                <Form.Control
                    id="text"
                    name='text'
                    as="textarea"
                    rows={3}
                    {...register('text', {
                        onChange: validatePostContent
                    })}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Выберите картинку:</Form.Label>
                <Form.Control
                    type="file"
                    onChange={(e) => {
                        imagePreview(e.target.files[0], setSelectedImage, setPreviewImage)
                        validatePostContent()
                    }}
                    accept="image/*"
                    className='mb-3'
                    ref={ref}
                />
            </Form.Group>
            {previewImage && (
                <>
                    <img
                        src={previewImage}
                        className="img-thumbnail mb-2 d-block mx-auto"
                        alt="Preview"
                        style={{ maxHeight: '200px' }}
                    />
                    <button className='btn btn-outline-dark mb-2 d-block mx-auto' onClick={handleDeleteImage}>Удалить картинку</button>
                </>
            )}
            {formState.errors.submit && (
                <div className="alert alert-danger" role="alert">{formState.errors.submit.message}</div>
            )}
            <Button variant="dark" type="submit" className='m-auto d-block'>{post ? 'Обновить' : 'Опубликовать'}</Button>
            {post && (<Button variant="outline-dark" onClick={onCancel} className='m-auto d-block mt-2'>Отмена</Button>)}
        </Form>
    )
}
export default CreatePostForm