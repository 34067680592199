import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useProfile } from "../modules/profileContext"
import { Card, Dropdown, Button, Modal, Image } from "react-bootstrap"
import { isPostEditable, getFullDate } from "../modules/utils"
import Api from "../modules/Api"
import CreatePostForm from "./createPostForm"
import Comments from "./comments"
import CreateCommentForm from "./createCommentForm"
import likeIcon from "../images/like.png"
import noLikeIcon from "../images/no_like.png"
import commentIcon from "../images/comment.png"

const Post = ({ post, onGetPost, onDeletePost, isFeed, index }) => {
    const navigate = useNavigate()
    const { profile } = useProfile()
    const [editingPostId, setEditingPostId] = useState(null)
    const [postToDelete, setPostToDelete] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [author, setAuthor] = useState(null)
    const [showComments, setShowComments] = useState(false)
    const [comments, setComments] = useState([])
    const [answerOnComment, setAnswerOnComment] = useState(null)

    const getPost = async (postId) => {
        const { data, status } = await Api.Post.get(postId)
        if (status === 200) {
            onGetPost(data, postId)
        }
    }

    const likePost = async (postId) => {
        const { status } = await Api.Post.like(postId)
        if (status === 204) {
            getPost(postId)
        }
    }

    const handleDeletePost = async () => {
        if (postToDelete) {
            const { status } = await Api.Post.delete(postToDelete.id)
            if (status === 204) {
                onDeletePost(postToDelete)
                setShowDeleteModal(false)
                setPostToDelete(null)
            }
        }
    }

    const getAuthor = async () => {
        if (!isFeed) return
        const { status, data } = await Api.User.get(post.author_id)
        if (status == 200) {
            setAuthor(data.data)
        }
    }

    useEffect(() => {
        getAuthor()
        getComments()
    }, [post])


    const getComments = async () => {
        const commentsResult = await Api.Post.getComments(post.id)
        if (commentsResult.status === 200) {
            const users = await Promise.all(
                commentsResult.data.data.map(async (comment) => {
                    if (comment.author_id) {
                        const { data, status } = await Api.User.get(comment.author_id)
                        if (status === 200) {
                            return data.data
                        }
                    }
                    return null
                })
            )
            setComments(() => {
                const newComments = commentsResult.data.data.map((comment, index) => {
                    const user = users[index]
                    return user ? { ...comment, author: user } : comment
                })
                return newComments
            })
        }
    }

    useEffect(() => {
        if (showComments) {
            getComments()
        }
    }, [showComments])

    return (
        <>
            <div key={index} className='bg-transparent mb-3'>
                {editingPostId === post.id ? (
                    <CreatePostForm
                        post={post}
                        onCancel={() => {
                            setEditingPostId(null)
                        }}
                        onPostUpdated={() => {
                            setEditingPostId(null)
                            getPost(post.id)
                        }}
                    />
                ) : (
                    <Card>
                        <Card.Header style={{ cursor: author ? "pointer" : "auto" }} onClick={() => {
                            if (author) {
                                navigate(`/profile/${author.id}`)
                            }
                        }}>
                            {author && <Image src={`${Api.host}${author.avatar}`} style={{ width: "5rem", height: "5rem" }} roundedCircle />}
                            <small className="text-muted">{author && author.name} {getFullDate(post.date_created)}</small>
                        </Card.Header>
                        <Card.Body>
                            {post.text && <Card.Text className="text-break">{post.text}</Card.Text>}
                            {post.image && <Card.Img src={`${Api.host}${post.image}`} style={{ maxHeight: "20rem", objectFit: "cover" }} alt="Post image" onClick={(e) => {
                                if (document.fullscreenElement) document.exitFullscreen()
                                else e.currentTarget.requestFullscreen()
                            }} />}
                        </Card.Body>
                        <Card.Footer className="d-flex justify-content-between">
                            <div className="likeContainer">
                                <img className='likeIcon' src={post.is_liked_by_user && likeIcon || noLikeIcon} onClick={() => likePost(post.id)}></img>
                                <p className="likeCount text-muted">{post.likes}</p>
                            </div>
                            <div className="likeContainer m-auto">
                                <img className="likeIcon" src={commentIcon} onClick={() => setShowComments(!showComments)}></img>
                                <p className="likeCount px-1">{comments?.length || 0}</p>
                            </div>
                            {profile?.id == post.author_id && (
                                <Dropdown>
                                    <Dropdown.Toggle variant="dark">Действия</Dropdown.Toggle>
                                    <Dropdown.Menu variant='dark'>
                                        <Dropdown.Item onClick={() => setEditingPostId(post.id)} disabled={!isPostEditable(post.date_created)}>Редактировать</Dropdown.Item>
                                        <Dropdown.Item className="text-danger" onClick={() => {
                                            setPostToDelete(post)
                                            setShowDeleteModal(true)
                                        }}>Удалить</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </Card.Footer>
                        {(showComments && comments) &&
                            <>
                                <Comments
                                    comments={comments}
                                    setComments={setComments}
                                    setAnswerOnComment={setAnswerOnComment}
                                />
                                <CreateCommentForm
                                    postId={post.id}
                                    onCommentSent={getComments}
                                    answerOnComment={answerOnComment}
                                    setAnswerOnComment={setAnswerOnComment}
                                />
                            </>
                        }
                    </Card>
                )}
            </div>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Удалить пост</Modal.Title>
                </Modal.Header>
                <Modal.Body>Вы уверены, что хотите удалить этот пост?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Отменить
                    </Button>
                    <Button variant="danger" onClick={handleDeletePost}>
                        Удалить
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Post