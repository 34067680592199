const Message = ({ message, isOwnMessage, isSelected }) => {
    return (
        <div className={`message-container ${isOwnMessage ? "self-message-container" : "other-message-container"}`} style={{ opacity: isSelected ? 0.5 : 1 }}>
            <div>
                <div className={`text-break message w-auto ${isOwnMessage ? "self-message" : "other-message"}`}>
                    {message.text}
                </div>
                <div className={`text-white d-flex ${isOwnMessage ? "justify-content-end" : "justify-content-start"}`}>
                    <small className={`message-time d-flex`}>
                        {new Date(message.date_created).toLocaleTimeString()}
                    </small>
                    {message.is_edited && <small className={`d-flex mx-2`}>ред.</small>}
                    {isOwnMessage && <>
                        <small className={`d-inline-block`} style={{ opacity: message.is_read ? 0.5 : 1 }}>&#10003;</small>
                        {message.is_read && <small className={`d-inline-block`} style={{ opacity: 0.5 }}>&#10003;</small>}
                    </>}
                </div>
            </div>
        </div>
    )
}
export default Message