export const getFullDate = (date) => new Date(date).toLocaleDateString('ru-RU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
})

export const canSendMessage = (profile, chats) => {
    const isChat = chats.some(user => user.id === profile.id)
    return profile.message_privacy === "all" || isChat || profile.friendship_status == "accepted"
}

export const formatMessageDate = (date) => {
    const today = new Date()
    const yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)

    if (date.toDateString() === today.toDateString()) {
        return 'Сегодня'
    }
    else if (date.toDateString() === yesterday.toDateString()) {
        return 'Вчера'
    }
    else {
        return date.toLocaleDateString('ru-RU', { day: 'numeric', month: 'long' })
    }
}

export const isSameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
}

export const isPostEditable = (date_created) => Math.abs(new Date() - new Date(date_created)) / 36e5 <= 8
export const isMessageEditable = isPostEditable // Типа одно и то же, но может же потом быть и не одно и то же, поэтому на всякий случай две разные функции. 8 часов от создания сообщения - можно редактировать

export const maskEmail = (string) => string.replace(
    /(..)(.{1,2})(?=.*@)/g,
    (_, a, b) => a + '*'.repeat(b.length)
)

export const getCookie = (name) => document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))?.[2]