import { useNavigate } from "react-router-dom"
import { Card, Row, Button, ButtonGroup } from 'react-bootstrap'
import Api from "../modules/Api"

const UsersCards = ({ users, requestButtons, onUpdate }) => {
    const navigate = useNavigate()

    const handleFriendRequest = async (action, friendship_id) => {
        let response = null
        if (action === "accept") {
            response = await Api.User.updateFrindshipStatus(friendship_id, { response: "accepted" })
        }
        else if (action === "reject") {
            response = await Api.User.updateFrindshipStatus(friendship_id, { response: "rejected" })
        }
        if (response.status === 204) {
            if (onUpdate) onUpdate()
        }
    }

    return (
        <Row className="justify-content-center w-100 m-auto">
            {users.map(user => (
                <Card
                    key={user.id}
                    className="text-center text-white bg-dark shadow mb-4 rounded m-1 text-break"
                    style={{ cursor: "pointer", maxWidth: "12rem", transform: "scale(1)", transition: "transform .2s" }}
                    onClick={() => navigate(`/profile/${user.id}`)}
                    onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                    onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}>
                    <Card.Img className="mt-1" src={`${Api.host}${user.avatar}`} alt={user.name} style={{height:"10rem"}}/>
                    <Card.Body>
                        <Card.Title className="fw-bold">{user.name}</Card.Title>
                        <Card.Text className="mb-2">{user.username}</Card.Text>
                    </Card.Body>
                    {requestButtons && (
                        <Card.Footer>
                            <ButtonGroup className="w-100">
                                <Button variant="outline-light text-success" onClick={(e) => {
                                    e.stopPropagation()
                                    handleFriendRequest("accept", user.friendship_id)
                                }}>Принять</Button>
                                <Button variant="outline-light text-danger" onClick={(e) => {
                                    e.stopPropagation()
                                    handleFriendRequest("reject", user.friendship_id)
                                }}>Отклонить</Button>
                            </ButtonGroup>
                        </Card.Footer>
                    )}
                </Card>
            ))}
        </Row>
    )
}

export default UsersCards