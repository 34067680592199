import { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import Api from "../modules/Api"
import Post from "./post"
import Loader from "./loader"
import CreatePostForm from "./createPostForm"

const Feed = () => {
    const [posts, setPosts] = useState(null)

    const getPosts = async () => {
        const { status, data } = await Api.Post.getAll()
        if (status == 200) {
            setPosts(data.data)
        }
    }

    useEffect(() => {
        getPosts()
    }, [])

    if (!posts) return <Loader pleaseEnter={true} />

    return (
        <Col xs={12} sm={12} md={8} lg={8} xl={8} className="m-auto mt-3">
            <div className="bg-light mb-3">
                <CreatePostForm onPostCreated={getPosts} />
            </div>
            {posts.length > 0 ? (
                posts.map((post, index) => (
                    <Post
                        post={post}
                        onGetPost={(data, postId) => setPosts(posts.map(post => post.id === postId ? data.data : post))}
                        onDeletePost={(postToDelete) => setPosts(posts.filter(post => post.id !== postToDelete.id))}
                        isFeed={true}
                        index={index}
                    />
                ))
            ) : (
                <div className='bg-transparent text-center text-muted'>Посты скоро появятся</div>
            )}
        </Col>
    )
}

export default Feed