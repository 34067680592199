import { React, useState } from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap"
import Api from "../modules/Api"
import { maskEmail } from "../modules/utils"

const ResetPasswordForm = () => {
    const [isSent, setIsSent] = useState(false)
    const [response, setResponse] = useState({ data: { errors: [] }, status: "" })
    const { register, handleSubmit, formState } = useForm({ mode: "onChange" })

    const onSubmit = async (data) => {
        let email = null
        let { data:result, status } = await Api.User.getAll()
        if (status === 200) {
            email = result.data.find((user) => user.username == data.login || user.email == data.login)?.email
            if (email) {
                let { data:result, status } = await Api.User.sendMailForgotPassword({email: email})
                if (status === 204) {
                    setIsSent(true)
                    setResponse({status: status, data: email})
                }
                else {
                    setResponse({status: status, data: result})
                }
            }
            else {
                setResponse({ status: 404, data: { errors: ["User not found"] } })
            }
        }
    }

    const closeModal = () => {
        setResponse({ data: { errors: [] }, status: "" })
    }

    return (
        <Container className="minHeight">
            <Row className="justify-content-center">
                <Col md={6} className="mt-3 pb-3 bg-light rounded shadow">
                    <h1 className="text-center">Восстановление пароля</h1>
                    {isSent ? <>
                        <p>Письмо отправлено на <span className="fw-bold">{maskEmail(response.data)}</span></p>
                        <div className="d-grid">
                            <Link to="/login" className="btn btn-dark">Войти</Link>
                        </div>
                    </>
                        :
                        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="login">Электронная почта или имя пользователя</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="login"
                                    name="login"
                                    {...register("login", { required: true })}
                                    isValid={formState.touchedFields.login && !formState.errors.login}
                                    isInvalid={!!formState.errors.login}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formState.errors.login?.type === "required" && "Пожалуйста, введите электронную почту или имя пользователя"}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="d-grid">
                                <button type="submit" className="btn btn-dark mt-2">Получить письмо</button>
                            </div>
                        </Form>
                    }
                </Col>
            </Row>
            {
                response.data.errors &&
                <Modal show={response.status} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Упс...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{`Что-то пошло не так: ${response.data.errors.join('\n')}`}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>Закрыть</Button>
                    </Modal.Footer>
                </Modal>
            }
        </Container>
    )
}

export default ResetPasswordForm